import React from "react"
import { Svg } from "../components/Base"

export default function Code(props) {
  return (
    <Svg
      id="a594ac37-6d44-4297-8862-1cbd9c01c0b7"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1126.19355 855"
      {...props}
    >
      <path
        d="M581.7675,778.43794C339.36866,759.99137,50.29677,572.17166,65.01385,378.78054S359.70017,5.35846,602.099,23.805,984.03089,403.667,969.31381,597.05809,824.16633,796.88451,581.7675,778.43794Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <ellipse cx="565.194" cy="756" rx="506" ry="31" fill="#3f3d56" />
      <ellipse cx="565.194" cy="755.5" rx="431" ry="20.5" opacity="0.1" />
      <ellipse cx="212.194" cy="836.5" rx="212.194" ry="18.5" fill="#3f3d56" />
      <ellipse
        cx="212.194"
        cy="836.202"
        rx="180.742"
        ry="12.234"
        opacity="0.1"
      />
      <rect x="196.194" y="162" width="752" height="590" fill="#3f3d56" />
      <path
        d="M600.54135,265.12914H415.02363a2.25143,2.25143,0,1,1,0-4.50285H600.54135a2.25143,2.25143,0,0,1,0,4.50285Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M803.6202,289.89486H511.83506a2.25143,2.25143,0,0,1,0-4.50286H803.6202a2.25143,2.25143,0,1,1,0,4.50286Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M601.44192,314.66057H415.9242a2.25143,2.25143,0,1,1,0-4.50286H601.44192a2.25143,2.25143,0,1,1,0,4.50286Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M803.6202,315.11086H618.10249a2.25143,2.25143,0,0,1,0-4.50286H803.6202a2.25143,2.25143,0,1,1,0,4.50286Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M601.8922,339.42629H416.37449a2.25143,2.25143,0,0,1,0-4.50286H601.8922a2.25143,2.25143,0,1,1,0,4.50286Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M602.34249,364.192H416.82477a2.25143,2.25143,0,0,1,0-4.50286H602.34249a2.25143,2.25143,0,1,1,0,4.50286Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M686.54592,265.57943H633.4122a2.25143,2.25143,0,0,1,0-4.50286h53.13372a2.25143,2.25143,0,1,1,0,4.50286Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M686.54592,339.42629H633.4122a2.25143,2.25143,0,0,1,0-4.50286h53.13372a2.25143,2.25143,0,1,1,0,4.50286Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M686.54592,363.74171H633.4122a2.25143,2.25143,0,1,1,0-4.50285h53.13372a2.25143,2.25143,0,1,1,0,4.50285Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M775.70249,363.74171H722.56877a2.25143,2.25143,0,0,1,0-4.50285h53.13372a2.25143,2.25143,0,1,1,0,4.50285Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M467.70706,289.89486H414.57335a2.25143,2.25143,0,0,1,0-4.50286h53.13371a2.25143,2.25143,0,0,1,0,4.50286Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M600.76649,629.86057H415.24877a2.25143,2.25143,0,0,1,0-4.50286H600.76649a2.25143,2.25143,0,0,1,0,4.50286Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M599.86592,679.392H414.3482a2.25143,2.25143,0,1,1,0-4.50286H599.86592a2.25143,2.25143,0,0,1,0,4.50286Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M600.3162,704.15771H414.79849a2.25143,2.25143,0,1,1,0-4.50285H600.3162a2.25143,2.25143,0,1,1,0,4.50285Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M679.56649,679.84229H626.43277a2.25143,2.25143,0,0,1,0-4.50286h53.13372a2.25143,2.25143,0,0,1,0,4.50286Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M679.56649,704.15771H626.43277a2.25142,2.25142,0,0,1,0-4.50285h53.13372a2.25142,2.25142,0,1,1,0,4.50285Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M768.72306,679.84229H715.58935a2.25143,2.25143,0,1,1,0-4.50286h53.13371a2.25143,2.25143,0,0,1,0,4.50286Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M803.84535,654.62629H512.0602a2.25143,2.25143,0,0,1,0-4.50286H803.84535a2.25143,2.25143,0,0,1,0,4.50286Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M467.9322,654.62629H414.79849a2.25143,2.25143,0,0,1,0-4.50286H467.9322a2.25143,2.25143,0,0,1,0,4.50286Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M803.84535,729.37371H512.0602a2.25143,2.25143,0,1,1,0-4.50285H803.84535a2.25142,2.25142,0,0,1,0,4.50285Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <path
        d="M467.9322,729.37371H414.79849a2.25143,2.25143,0,1,1,0-4.50285H467.9322a2.25143,2.25143,0,1,1,0,4.50285Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <polygon
        points="519.06 401.13 447.014 472.275 519.06 543.42 535.27 527.21 480.786 472.725 535.72 417.79 519.06 401.13"
        fill="#319795"
      />
      <polygon
        points="625.327 401.13 697.373 472.275 625.327 543.42 609.117 527.21 663.602 472.725 608.667 417.79 625.327 401.13"
        fill="#319795"
      />
      <circle cx="216.194" cy="177" r="9" fill="#319795" />
      <circle cx="240.194" cy="177" r="9" fill="#319795" />
      <circle cx="264.194" cy="177" r="9" fill="#319795" />
      <rect x="196.194" y="192" width="752" height="3" opacity="0.1" />
      <path
        d="M1163.09677,629.95357c0,63.77108-37.91089,86.03756-84.67635,86.03756s-84.67635-22.26648-84.67635-86.03756,84.67635-144.898,84.67635-144.898S1163.09677,566.18249,1163.09677,629.95357Z"
        transform="translate(-36.903 -22.5)"
        fill="#f2f2f2"
      />
      <polygon
        points="1038.433 683.736 1039.3 630.365 1075.391 564.338 1039.436 621.993 1039.826 597.995 1064.699 550.226 1039.929 591.645 1039.929 591.645 1040.63 548.484 1067.265 510.453 1040.74 541.697 1041.178 462.556 1038.425 567.325 1038.651 563.003 1011.571 521.552 1038.217 571.3 1035.694 619.503 1035.619 618.223 1004.4 574.602 1035.524 622.743 1035.209 628.771 1035.152 628.862 1035.178 629.357 1028.776 751.653 1037.329 751.653 1038.355 688.485 1069.404 640.461 1038.433 683.736"
        fill="#3f3d56"
      />
      <path
        d="M159.52481,773.59144a43.12568,43.12568,0,0,1-8.17805,3.50253c-5.07883,1.38739-10.50718.8784-15.63716,2.06274-2.10034.4849-4.38629,1.49916-5.05424,3.54866-.48995,1.50331.04306,3.12668.55347,4.62317,5.66852,16.61991,9.08515,34.26625,18.12518,49.32052a74.64075,74.64075,0,0,0,13.99269,16.98766c2.05512,1.85228,4.38405,3.6643,7.13541,3.95491a7.24769,7.24769,0,0,0,7.37055-10.182c-.81056-1.8011-2.31727-3.19619-3.289-4.91569a18.757,18.757,0,0,1-1.81948-6.99071c-.89312-7.10831-1.7535-14.53764.70942-21.26517,1.6519-4.5122,4.68207-8.35894,7.20641-12.4475s4.62171-8.7719,4.06-13.544c-.38332-3.25659-1.97189-6.23685-3.65-9.054-1.68146-2.8227-5.00472-10.40837-8.38287-11.35035C169.16047,766.86441,162.583,772.02411,159.52481,773.59144Z"
        transform="translate(-36.903 -22.5)"
        fill="#2f2e41"
      />
      <path
        d="M251.95981,831.00007a14.01882,14.01882,0,0,1-.66321,4.74531c-1.05756,2.79253-3.681,4.70608-5.03631,7.36685-1.885,3.70061-1.03063,8.17372.21823,12.13454.78295,2.48316,1.8099,5.067,3.91246,6.60271a12.89278,12.89278,0,0,0,5.95383,1.90719c18.03627,2.49619,36.35793,1.064,54.5586,1.587,3.31731.09532,6.82944.21147,9.72521-1.40969s4.686-5.6968,2.71651-8.36789c-1.76854-2.39854-5.26537-2.37621-8.24208-2.51756a33.912,33.912,0,0,1-20.913-8.61479,15.11172,15.11172,0,0,1-2.93335-3.37658,20.75292,20.75292,0,0,1-1.53436-3.51757,156.79876,156.79876,0,0,0-9.08354-20.11857c-.97877-1.81492-2.087-3.705-3.906-4.67631a10.32271,10.32271,0,0,0-4.23932-.92554c-4.53631-.294-16.28319-1.83785-19.791,1.68963C249.46137,816.76814,252.03167,826.86031,251.95981,831.00007Z"
        transform="translate(-36.903 -22.5)"
        fill="#2f2e41"
      />
      <circle cx="242.971" cy="376.72" r="28.975" fill="#fbbebe" />
      <path
        d="M253.709,423.14584c-1.72342,4.6139-4.64671,8.94711-8.90039,11.42995l40.0746,8.17466a42.45319,42.45319,0,0,1-.56181-17.869,10.30012,10.30012,0,0,0,.23975-4.409c-.75354-2.90746-4.02646-4.30693-6.95489-4.97449-7.31406-1.66731-13.7821-2.56141-20.57892-5.79173C254.9498,413.26279,255.24069,419.04525,253.709,423.14584Z"
        transform="translate(-36.903 -22.5)"
        fill="#fbbebe"
      />
      <path
        d="M244.47385,423.72685a31.33845,31.33845,0,0,1,27.31312.71368c5.13776,2.70749,9.39191,6.79432,13.57151,10.82645,3.85264,3.71671,7.96244,7.97238,8.23588,13.31859.13245,2.58993-.67591,5.12286-1.08406,7.68385a25.76566,25.76566,0,0,0,13.78,26.46946c3.1836,5.30339,3.84065,11.7287,3.88948,17.91408.185,23.43592-7.20915,47.16094-1.93041,69.99538,1.23937,5.36117,3.16161,10.55391,4.2323,15.95129,1.38681,6.991,1.31835,14.17959,2.04391,21.26977a95.26625,95.26625,0,0,0,5.11322,22.49547c-1.744,2.7737-5.37357,3.83728-8.64228,3.61264s-6.3252-1.64311-9.24191-3.13567c-14.12327-7.22727-27.05918-16.75729-41.687-22.89955-9.67764-4.06367-19.98746-6.59488-29.37589-11.28823s-18.11988-12.14194-20.99049-22.238c-1.24124-4.36546-1.31765-8.96508-1.3854-13.50306-.28534-19.11376-.55046-38.43617,3.61-57.09382,3.669-16.45362,10.74145-32.1938,12.28438-48.98077.50223-5.46424.51295-11.31378,3.56594-15.87332C232.09135,432.52045,241.40863,430.85143,244.47385,423.72685Z"
        transform="translate(-36.903 -22.5)"
        fill="#2f2e41"
      />
      <path
        d="M263.3948,399.62932c1.70735-2.22683,5.00024-2.53051,7.72786-1.87181s5.24265,2.06449,7.99738,2.59858a4.67544,4.67544,0,0,0,3.74325-.52575,5.44429,5.44429,0,0,0,1.61547-3.57028,68.16318,68.16318,0,0,0,.67089-17.38048,5.14652,5.14652,0,0,1,.15877-2.43744c.682-1.6434,2.7627-2.03456,4.46209-2.56176a13.31574,13.31574,0,0,0,9.01289-13.63308,5.42087,5.42087,0,0,0-1.6567-3.74055c-1.17523-.95765-2.82014-1.01649-4.33609-1.02867q-11.68307-.09382-23.36661.00565c-2.88278.02455-5.91029.09552-8.40937,1.53276-1.84769,1.06263-3.22171,2.773-4.88046,4.11154-4.32621,3.49111-10.17785,4.20145-15.44116,5.99071a15.30822,15.30822,0,0,0-6.76753,4.03565c-1.75035,1.99965-2.5919,4.97311-1.49261,7.39258a26.25205,26.25205,0,0,0,1.88685,2.9039c3.10463,4.96416,1.03778,11.40306,1.50139,17.23972.57892,7.28838,11.36495,19.11157,18.67879,20.83626C266.30761,422.31124,259.486,404.41359,263.3948,399.62932Z"
        transform="translate(-36.903 -22.5)"
        fill="#2f2e41"
      />
      <path
        d="M247.0375,719.1826c.74512,5.32168,1.7007,10.66172,1.44525,16.02924-.35664,7.49365-3.05912,14.71381-3.55872,22.19929-.37315,5.59084.48989,11.24214-.28652,16.79137-.49715,3.55325-1.65585,6.97849-2.37315,10.49392a43.39491,43.39491,0,0,0,6.97307,33.26545,5.44788,5.44788,0,0,0,2.02339,1.961,5.7137,5.7137,0,0,0,2.50788.3318,179.9472,179.9472,0,0,0,25.17023-2.28749,2.41432,2.41432,0,0,0,1.61622-.71311,2.26567,2.26567,0,0,0,.351-.954c1.54379-7.936-1.97676-15.89332-2.872-23.92837-.95593-8.57968,1.10271-17.17733,3.14612-25.56477a113.54767,113.54767,0,0,1,3.46738-12.22773c.9877-2.69735,2.18054-5.32086,3.0366-8.06284a61.46035,61.46035,0,0,0,2.202-13.20261q.89161-10.57524.94476-21.19892a88.95779,88.95779,0,0,0-1.66511-20.01c-1.878-8.338-5.7238-16.08066-9.53591-23.73028a15.02852,15.02852,0,0,0-5.61784,2.67008,125.37991,125.37991,0,0,1-15.35,8.48057c-3.56655,1.65676-10.52195,2.83342-12.78554,6.28577-2.23228,3.40461-.792,10.35082-.62145,14.10572Q245.69419,709.58425,247.0375,719.1826Z"
        transform="translate(-36.903 -22.5)"
        fill="#2f2e41"
      />
      <path
        d="M247.0375,719.1826c.74512,5.32168,1.7007,10.66172,1.44525,16.02924-.35664,7.49365-3.05912,14.71381-3.55872,22.19929-.37315,5.59084.48989,11.24214-.28652,16.79137-.49715,3.55325-1.65585,6.97849-2.37315,10.49392a43.39491,43.39491,0,0,0,6.97307,33.26545,5.44788,5.44788,0,0,0,2.02339,1.961,5.7137,5.7137,0,0,0,2.50788.3318,179.9472,179.9472,0,0,0,25.17023-2.28749,2.41432,2.41432,0,0,0,1.61622-.71311,2.26567,2.26567,0,0,0,.351-.954c1.54379-7.936-1.97676-15.89332-2.872-23.92837-.95593-8.57968,1.10271-17.17733,3.14612-25.56477a113.54767,113.54767,0,0,1,3.46738-12.22773c.9877-2.69735,2.18054-5.32086,3.0366-8.06284a61.46035,61.46035,0,0,0,2.202-13.20261q.89161-10.57524.94476-21.19892a88.95779,88.95779,0,0,0-1.66511-20.01c-1.878-8.338-5.7238-16.08066-9.53591-23.73028a15.02852,15.02852,0,0,0-5.61784,2.67008,125.37991,125.37991,0,0,1-15.35,8.48057c-3.56655,1.65676-10.52195,2.83342-12.78554,6.28577-2.23228,3.40461-.792,10.35082-.62145,14.10572Q245.69419,709.58425,247.0375,719.1826Z"
        transform="translate(-36.903 -22.5)"
        opacity="0.1"
      />
      <path
        d="M207.86438,605.518c-3.84776,10.35941,1.80816,21.55375,6.722,31.45209,12.106,24.38615,20.51267,50.40745,28.87447,76.31727.62635,1.9408,1.2522,4.05818.55393,5.97428a7.82535,7.82535,0,0,1-2.82176,3.347c-5.76549,4.37915-13.10677,5.93873-19.97473,8.2298a90.18354,90.18354,0,0,0-18.94265,8.87995c-4.97678,3.08387-9.63891,6.64386-14.29189,10.1975l-14.812,11.31248a9.903,9.903,0,0,0-2.47578,2.39658c-1.4286,2.24131-.84344,5.16734-.13218,7.72829a131.43212,131.43212,0,0,0,7.25213,19.792,6.911,6.911,0,0,0,2.63,3.44664c2.31193,1.24241,5.13971-.405,6.90515-2.34714s3.29242-4.35894,5.78108-5.1927c2.48574-.83278,5.2216.17573,7.81767-.18871,4.19867-.58943,6.98621-4.45724,10.01273-7.42649,12.36533-12.13133,33.01009-11.25124,46.61244-21.97723a59.22781,59.22781,0,0,1,6.231-4.86753c2.29954-1.35929,4.90732-2.10533,7.29316-3.30673,7.25663-3.65411,11.81865-11.20854,14.26244-18.957s3.14074-15.92786,4.89686-23.86053c.90786-4.10092,2.10291-8.15769,2.50676-12.33845a66.09392,66.09392,0,0,0-.25308-11.6661L287.604,621.74782c-.3996-4.94331-.79987-9.89239-1.57574-14.79076-14.57664.5211-29.14284,1.10754-43.72778,1.28C230.69491,608.37423,218.87609,609.18554,207.86438,605.518Z"
        transform="translate(-36.903 -22.5)"
        fill="#2f2e41"
      />
      <path
        d="M202.50573,619.721c1.97737,3.18011,6.79016,3.11442,10.10427,1.37092s5.8888-4.67259,9.21071-6.40118c4.46387-2.32282,9.74611-2.23547,14.77617-2.0934,7.96357.22492,16.02491.46432,23.68692,2.64671,7.92486,2.25727,15.63969,6.60332,23.834,5.7363,1.4259-.15087,2.98505-.55979,3.76106-1.76551a5.54448,5.54448,0,0,0,.61966-2.83582l.26794-7.159a6.46407,6.46407,0,0,0-.57-3.62533,6.08162,6.08162,0,0,0-2.71588-2.07944c-11.66338-5.64612-24.96458-6.69742-37.91511-7.14107q-9.87458-.33828-19.75748-.27827c-5.591.03409-12.303-.77486-17.73873.71906C202.77416,598.81994,198.8047,613.76883,202.50573,619.721Z"
        transform="translate(-36.903 -22.5)"
        fill="#2f2e41"
      />
      <path
        d="M275.74249,432.22556c-15.28217-.13537-29.131-10.22251-44.4118-10.46824a11.51447,11.51447,0,0,0-5.39263.93046c-2.12932,1.07263-3.52928,3.14668-5.14679,4.89829-4.51828,4.89285-11.0612,7.50531-15.703,12.28109-4.35234,4.47793-6.77287,11.14957-5.05456,17.15307s8.20353,10.60849,14.279,9.16523A36.80081,36.80081,0,0,1,228.64955,446.706a72.88717,72.88717,0,0,1,11.20533-5.88209c6.86792-3.11167,14.54778-6.28282,21.71787-3.95031a30.63793,30.63793,0,0,1,7.40918,4.04034l11.95388,8.03C279.54293,443.44994,278.29044,437.28837,275.74249,432.22556Z"
        transform="translate(-36.903 -22.5)"
        fill="#319795"
      />
      <path
        d="M212.55243,460.87394c-2.0293,2.53158.894,6.19965.44656,9.41317-.29726,2.13512-2.05431,3.76539-2.786,5.79313-.75791,2.10039-.356,4.427-.64344,6.64132a28.48866,28.48866,0,0,1-1.2751,4.72964,84.97452,84.97452,0,0,0-2.68638,14.02574l-2.6749,19.97555a75.68111,75.68111,0,0,0-.846,9.07789,27.10744,27.10744,0,0,1-.45211,6.78677c-.59232,2.183-1.90386,4.09275-2.78616,6.17547s-1.29127,4.602-.09937,6.52435c-3.99144,3.32456-5.17826,9.85376-2.38732,14.235a7.89943,7.89943,0,0,1,1.23013,2.26518,5.81983,5.81983,0,0,1-.54176,3.34034l-2.7047,7.20665c-.69232,1.84469-.91128,4.65314,1.01352,5.07422a17.00617,17.00617,0,0,0-3.65133,5.76681,6.86617,6.86617,0,0,0,.97247,6.50559,8.28879,8.28879,0,0,1,1.69441,2.0563,5.47266,5.47266,0,0,1-.19586,3.24315,11.90573,11.90573,0,0,0,1.473,9.33345,8.37146,8.37146,0,0,0,8.41846,3.60852c5.41045-1.22031,10.27226-3.1467,15.72755-2.14571,11.746,2.15529,23.52979,4.31989,34.90618,7.952,12.984,4.1454,25.55606,10.22443,39.12586,11.50045a3.79783,3.79783,0,0,0,2.61662-.407,3.93069,3.93069,0,0,0,1.21-2.48763q2.097-9.83241,4.19405-19.66481a202.36327,202.36327,0,0,0,3.85525-22.02765c.47742-4.78125.61351-9.58923.7493-14.39234l1.08084-38.23106a38.19731,38.19731,0,0,0-2.2-16.61961l-6.21748-19.8483c-3.54813-11.32685-11.68773-21.37125-14.58576-32.8816-1.83417-7.285-3.18913-15.10292-8.10789-20.781-7.01237-8.09492-19.1447-9.354-29.76373-7.96222-4.79727.62876-9.63561,1.71382-13.78522,4.20176-4.79825,2.87682-8.33308,7.40861-11.75286,11.8363C218.30489,454.34281,215.43623,457.27636,212.55243,460.87394Z"
        transform="translate(-36.903 -22.5)"
        fill="#319795"
      />
      <path
        d="M237.34735,573.32348c.87491,3.77256,2.71233,7.25107,3.837,10.95684,1.88437,6.20878,1.72443,12.89561,3.60511,19.10551.78937,2.60646,1.93305,5.109,2.49961,7.77276,1.32091,6.21057-.60773,12.6356-3.0035,18.51576s-5.31347,11.678-6.0912,17.97962c-.54778,4.43849.23913,9.45012,3.66018,12.33051,3.55169,2.99038,8.71308,2.78787,13.33894,2.39a14.01584,14.01584,0,0,0,4.84121-1.00807c3.53766-1.67361,5.02767-5.85984,5.85461-9.685,2.77128-12.81912,1.97957-26.10621,1.17422-39.19671l-2.10613-34.2342c-.40547-6.59081-.83988-13.31809-2.87081-19.64644-.73878-2.302-2.02646-6.95382-4.12917-8.42371-2.0826-1.45584-8.02994-1.52068-10.60853-1.64177C235.25108,547.97044,235.3867,564.856,237.34735,573.32348Z"
        transform="translate(-36.903 -22.5)"
        fill="#fbbebe"
      />
      <path
        d="M250.75137,446.9235c-2.47072-1.29382-5.58357-.736-7.97179.70446a24.56772,24.56772,0,0,0-5.9719,5.79818c-5.33892,6.51687-10.99072,13.99464-10.19889,22.38194.29689,3.14472,1.501,6.12087,2.31737,9.17226,3.57944,13.37964-.37874,27.53015.07542,41.37287.26243,7.99873,2.00293,15.86638,3.7363,23.67945.35375,1.59451.92034,3.44035,2.46284,3.97727,1.15873.40334,2.41033-.11521,3.55715-.55127A28.371,28.371,0,0,1,264.721,556.731c2.865-1.52869,1.79145-6.36261,1.14913-9.54579-1.90842-9.45767.68218-19.21438,3.82837-28.3353s6.91058-18.23032,7.43185-27.86453c.59061-10.91607-3.02875-21.57071-6.59987-31.903-.77948-2.25528-1.63134-4.6202-3.43861-6.17831a13.66523,13.66523,0,0,0-5.20292-2.377C258.09582,449.40594,254.25539,448.75842,250.75137,446.9235Z"
        transform="translate(-36.903 -22.5)"
        fill="#2f2e41"
      />
    </Svg>
  )
}
